import React, { ReactNode, useEffect, useState } from "react";
import { imgConfig } from "../../configs/imgConfig";
import { PageRoutes } from "../../appMenuAndRouting/appRoutes";
import { linksConfig } from "../../configs/linksConfig";
import { temporaryMetricsConfig } from "../../configs/temporaryMetricsConfig";

type PropsType = { children: ReactNode };
export const GetConfig = (props: PropsType) => {
    const [configChecked, setConfigChecked] = useState<boolean>(false);

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    useEffect(() => {
        if (!configChecked) {
            fetch(basepath + "assets/config.json")
                .then((response) => response.json())
                .then((result) => {
                    // применение метрик, временно до появление эндпоинта
                    result.pix_bi_all &&
                        (temporaryMetricsConfig.pix_bi_all = result.pix_bi_all);
                    result.pix_bi_portfolio &&
                        (temporaryMetricsConfig.pix_bi_portfolio =
                            result.pix_bi_portfolio);
                    result.qlik_all &&
                        (temporaryMetricsConfig.qlik_all = result.qlik_all);
                    result.qlik_portfolio &&
                        (temporaryMetricsConfig.qlik_portfolio = result.qlik_portfolio);
                    result.python_all &&
                        (temporaryMetricsConfig.python_all = result.python_all);
                    result.python_portfolio &&
                        (temporaryMetricsConfig.python_portfolio =
                            result.python_portfolio);
                    //изменение роутов
                    result.data_market_link &&
                        (PageRoutes.DataLab_ShopDataPage = result.data_market_link);
                    result.education_link &&
                        (PageRoutes.DataLab_EducationPage = result.education_link);

                    // изменение ссылок
                    result.guide_link && (linksConfig.guide_link = result.guide_link);
                    result.task_slider_last_element_link &&
                        (linksConfig.task_slider_last_element_link =
                            result.task_slider_last_element_link);
                    result.excel_link && (linksConfig.excel_link = result.excel_link);
                    result.publish_link &&
                        (linksConfig.publish_link = result.publish_link);
                    result.old_blps_market &&
                        (linksConfig.old_blps_market = result.old_blps_market);
                    result.old_brd_analog_market &&
                        (linksConfig.old_brd_analog_market =
                            result.old_brd_analog_market);
                    // ссылка на магазин используется из PageRoutes
                    // result.data_market_link &&
                    //     (linksConfig.new_market = result.data_market_link);
                    result.support_brd && (linksConfig.support_brd = result.support_brd);
                    result.support_blps &&
                        (linksConfig.support_blps = result.support_blps);
                    result.support_kf && (linksConfig.support_kf = result.support_kf);

                    // uuid картинки на аватар по умолчанию
                    result.default_avatar_image_uuid &&
                        (imgConfig.defaultAvatarUuid = result.default_avatar_image_uuid);
                    console.log("настройки в соответствии с Config:", result);
                })
                .catch((e) => {
                    console.log(
                        "Ошибка при обработке файла config.json, применяются настройки по умолчанию",
                        e
                    );
                })
                .finally(() => {
                    setConfigChecked(true);
                });
        }
    }, [configChecked, basepath]);
    if (!configChecked) return <></>;

    return <> {props.children}</>;
};
