import { BrowserRouter } from "react-router-dom";

import classes from "./App.module.css";

import { presetGpnDefault, Theme } from "@consta/uikit/Theme";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import { getAppSideBarMenuItems } from "../../appMenuAndRouting/appSideBarMenuItems";
import {
    appRedirectRoute,
    getAppRoutes,
    onLogoClickRoute,
    withoutFooterRoutes,
} from "../../appMenuAndRouting/appRoutes";

import logo from "assets/icons/icn_header-logo.svg";
import {
    getAppHeaderAdminMenuItems,
    getAppHeaderMenuItems,
} from "../../appMenuAndRouting/appHeaderMenuItems";
import AppHeader from "../../shared-front/AppHeader/AppHeader";
import AppFooter from "../../shared-front/AppFooter/AppFooter";
import AppRouter from "../../shared-front/AppRouter/AppRouter";
import React, { useCallback } from "react";
import { PortalsEnum } from "../../shared-front/AppHeader/sharedSideBarMenuItems";
import { useCreateLogMutation } from "../../store/labApi";
import { Location } from "history";
import { HeaderExtraActions } from "../HeaderExtraActions/HeaderExtraActions";
import { NotificationsBar } from "../NotificationsBar/NotificationsBar";

const App = () => {
    const [createLog] = useCreateLogMutation();
    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    console.log(
        "process.env.REACT_APP_IS_EXTERNAL_DOMAIN",
        process.env.REACT_APP_IS_EXTERNAL_DOMAIN,
        !!process.env.REACT_APP_IS_EXTERNAL_DOMAIN
    );
    const baseVersion = "1.16";
    const version = process.env.REACT_APP_DATE
        ? `${baseVersion}.${process.env.REACT_APP_DATE}`
        : baseVersion;
    console.log("version", version);
    const appLogo = (
        <img
            src={logo}
            style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "left top",
            }}
            alt={"logo"}
        />
    );
    const handlerChangeLocation = useCallback(
        (location: Location) => {
            createLog({
                event_type: "test",
                http_path: `${location.pathname}${location.search}`,
            });
        },
        [createLog]
    );

    return (
        <ErrorBoundary>
            <Theme preset={presetGpnDefault}>
                <BrowserRouter>
                    <div className={classes.container}>
                        <AppHeader
                            appLogo={appLogo}
                            // appBaseRoute={appBaseRoute}
                            portal={PortalsEnum.Laboratory}
                            appHeaderMenuItems={
                                isAdmin
                                    ? getAppHeaderAdminMenuItems()
                                    : getAppHeaderMenuItems()
                            }
                            appSideBarMenuItems={getAppSideBarMenuItems()}
                            onLogoClickRoute={onLogoClickRoute}
                            authMode={
                                !!process.env.REACT_APP_IS_EXTERNAL_DOMAIN &&
                                process.env.REACT_APP_IS_EXTERNAL_DOMAIN !== "false"
                            }
                            dqBarComponent={HeaderExtraActions}
                        />
                        <div className={classes.pageWrapper}>
                            <AppRouter
                                appRoutes={getAppRoutes()}
                                appRedirectRoute={appRedirectRoute}
                                onChangeLocation={handlerChangeLocation}
                            />
                        </div>
                        <AppFooter
                            portalName={"Дата-Платформе"}
                            questionEmail={"data-platform@gazprom-neft.ru"}
                            onLogoClickRoute={onLogoClickRoute}
                            withoutFooterRoutes={withoutFooterRoutes}
                            version={version}
                        />
                    </div>
                    <NotificationsBar />
                </BrowserRouter>
            </Theme>
        </ErrorBoundary>
    );
};

export default App;
