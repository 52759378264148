type LinksConfig = {
    guide_link: string;
    task_slider_last_element_link: string;
    excel_link: string;
    publish_link: string;
    old_blps_market: string;
    old_brd_analog_market: string;
    support_blps: string;
    support_brd: string;
    support_kf: string;
};

// настройки по умолчанию, новые настройки приходят из файла /public/assets/config.json
// для исключения использования не актуальных данных использовать только внутри компонент
export const linksConfig: LinksConfig = {
    guide_link: "knowledge?tags=SYSTEM_PROCESS",
    task_slider_last_element_link: "knowledge?tags=SYSTEM_TASK_STAGING",
    excel_link: "knowledge?tags=SYSTEM_EXCEL",
    publish_link: "knowledge?tags=SYSTEM_ANALYTICAL_APPLICATION",
    old_blps_market: "https://data-blps.gazprom-neft.local/data-market?tab=tables",
    old_brd_analog_market:
        "https://kb.gazprom-neft.local/pages/viewpage.action?pageId=131829725",
    // new_market: "https://market.berzhok.ru/release1/datasets/all",
    support_brd:
        "http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|697|147|272|670",
    support_blps:
        "http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|479|574|495",
    support_kf:
        "http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|697|698|365|453",
};
